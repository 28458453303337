$(function () {
    $(".child_destinations_slider").slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        // adaptiveHeight: true,
        arrows: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 1081,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 811,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
});
